<template>
    <p-modal
        button-label="Clear Inventory"
        size="lg"
        label="Clear Inventory"
        ref="ClearInventoryModal"
        @ok="handleModalOk"
    >
        <template v-slot:modal-header> </template>
        <template v-slot:default>
            <div class='container-fluid content-wrapper'>
                 <DCCsSelector class='container-fluid' @selection-change='selectedDCC = $event'/>
            </div>
        </template>

        <template v-slot:modal-footer="{ ok, cancel }">
            <b-row>
                <b-col>
                    <b-button variant="outline-primary" @click="cancel">Cancel</b-button>
                    <b-button variant="primary" @click="ok" class="ml-2">Clear Inventory</b-button>
                </b-col>
            </b-row>
        </template>
    </p-modal>
</template>

<script>

import axios from 'axios';
import DCCsSelector from '@/components/DCCsSelector.vue';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    props: {},
    data() {
        return {
             selectedDCC: []
        };
    },
     components: {
        DCCsSelector,
    },
    methods: {
        async handleModalOk() {
            var locId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
            this.$bvModal
                .msgBoxConfirm('You are about to set the stock on hand of items to ZERO. Are you sure this is what you want to do?')
                .then(value => {
                    if (value) {
                        axios.post('physicalinventory/clearinventory', {
                            DCCs: this.selectedDCC,
                            locationId: locId
                        })
                        .then(resp => {
                            if (resp) {
                                this.$toasted.global
                                    .app_success(
                                        `Inventory has been cleared`
                                    )
                                    .goAway(5000);
                            }
                        });
                    }
                })
        }
    }
};

</script>

<style scoped></style>
