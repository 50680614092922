<template>
    <div>
        <SearchScreen
            title="Purchase Orders"
            api-destination="purchaseorders"
            edit-key="id"
            :filters="filters"
            :fields="fields"
            :filterBy="filterBy"
            :use-bootstrap-selection="false"
            :show-set-to="false"
            ref="searchScreen"
            add-new-button
            :refreshSearchResults="refreshSearchResults"
            @selected="onSelection"
            @change="updateSearch"
            @selected-rows-changed="selectedRowsChanged"
        >
            <template v-slot:belowSearch>
                <p-form>
                    <b-row class="ml-3 mt-4 mr-2">
                        <p-checkbox
                            label="Proposed"
                            class="ml-2"
                            v-model="filters.proposed"
                        />
                        <p-checkbox
                            label="Open"
                            class="ml-3"
                            v-model="filters.open"
                        />
                        <p-checkbox
                            label="Closed"
                            class="ml-3"
                            v-model="filters.closed"
                        />
                        <b-col md="3" class="ml-md-auto">
                            <p-datepicker
                                label="PO Date: From"
                                vid="poStartDate"
                                v-model="filters.beginStartDate"
                            />
                        </b-col>
                        <b-col md="3">
                            <p-datepicker
                                label="PO Date: To"
                                vid="poEndDate"
                                v-model="filters.endStartDate"
                                rules="dateGreaterOrEqual:@poStartDate"
                                is-end-date
                            />
                        </b-col>
                        <b-col md="3">
                            <p-datepicker
                                label="Modified Date"
                                v-model="filters.modifiedStartDate"
                                @end-of-selected-day="e => {filters.modifiedEndDate = e}"
                            />
                        </b-col>
                    </b-row>
                </p-form>
            </template>
            <template v-slot:actionButtons>
                <p-button
                    variant="outline-primary"
                    class="ml-2"
                    :is-busy="isBusy"
                    :disabled="selectedPurchaseorders.length <= 0"
                    @click="showExportPurchaseOrder()"
                    >{{ printPOLabel }}</p-button
                >
                <p-button variant="outline-primary" class="ml-2" @click="workPurchaseOrders" :disabled="selectedPurchaseorders.length<=0">{{ workPOLabel}}</p-button>
                <p-button
                    variant="outline-danger"
                    class="ml-2"
                    :is-busy="isBusy"
                    :disabled="selectedPurchaseorders.length <= 0"
                    @click="deletePurchaseOrders()"
                    >{{ deletePOLabel }}</p-button> 
                <b-dropdown
                    class="ml-2"
                    text="Exports"
                    variant="outline-primary"
                >
                    <b-dropdown-item v-b-modal.outstandingCreditRequestModal
                        >Export Outstanding Credit Requests</b-dropdown-item
                    >
                    <b-dropdown-item v-b-modal.receivedNotYetInvoicedModal
                        >Received Not Yet invoiced</b-dropdown-item
                    >
                    <b-dropdown-item v-b-modal.openPurchaseOrderProductsModal
                        >Open Purchase Order Products</b-dropdown-item
                    >
                </b-dropdown>               
            </template>
        </SearchScreen>
        <OutstandingCreditRequestModal />
        <ReceivedNotYetInvoicedModal />
        <OpenPurchaseOrderProductsModal />
        <p-modal
            @ok="handleModalOk"
            size="sm"
            :disable-button="selectedPurchaseorders.length <= 0"
            label="Export POs"
            ref="exportPurchaseOrder"
            name="exportPurchaseOrder"
        >
            <template v-slot:default>
                <p-checkbox label="Include Retail" v-model="includeRetail" />
            </template>
        </p-modal>
    </div>
</template>

<script>
import axios from 'axios';
import SearchScreen from '../../components/SearchScreen.vue';
import {
    dateFormatter,
    currencyFormatter
} from '../../components/Common/Formatters.js';
import OutstandingCreditRequestModal from './Reports/OutstandingCreditRequestModal.vue';
import ReceivedNotYetInvoicedModal from './Reports/ReceivedNotYetInvoicedModal.vue';
import OpenPurchaseOrderProductsModal from './Reports/OpenPurchaseOrderProductsModal.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'POSearchFilter',
            selectedPurchaseorders: [],
            includeRetail: true,
            filters: {
                automatic: '',
                perPage: 100,
                sortBy: 'poNumber',
                sortDesc: true,
                searchBy: 'All',
                proposed: true,
                open: true,
                closed: false,
                modifiedStartDate: null,
                modifiedEndDate: null,
                beginStartDate: null,
                endStartDate: null
            },
            filterBy: [
                'All',
                'PO #',
                'Invoice #',
                'SKU',
                'Catalog #',
                'ISBN',
                'Supplier',
                'Buyer',
                'Reference #'
            ],
            refreshSearchResults: false,
            isBusy: false,
            fields: [
                {
                    key: 'poNumber',
                    label: 'PO Number',
                    sortable: true
                },
                {
                    key: 'supplierName',
                    sortable: true
                },
                {
                    key: 'buyer',
                    sortable: true
                },
                {
                    key: 'status',
                    sortable: true
                },
                {
                    key: 'purchaseOrderDate',
                    label: 'PO Date',
                    sortable: true,
                    formatter: dateFormatter
                },
                {
                    key: 'modifiedDate',
                    sortable: true,
                    formatter: dateFormatter
                },
                {
                    key: 'total',
                    sortable: true,
                    formatter: currencyFormatter,
                    thClass: 'text-right',
                    tdClass: 'text-right'
                }
            ],
            deletePOLabel: 'Delete PO',
            printPOLabel: 'Print PO',
            workPOLabel: 'Work PO'
        };
    },
    components: {
        SearchScreen,
        OutstandingCreditRequestModal,
        ReceivedNotYetInvoicedModal,
        OpenPurchaseOrderProductsModal
    },
     methods: {
        deletePurchaseOrders() {
            let confirmMessage = this.selectedPurchaseorders.length == 1 
                                    ? `Are you sure you want to delete Purchase Order: ${this.selectedPurchaseorders[0].poNumber}?`
                                    : `Are you sure you want to delete ${this.selectedPurchaseorders.length} Purchase Orders?`;
            let successMessage = this.selectedPurchaseorders.length == 1
                                    ? `Purchase Order ${this.selectedPurchaseorders[0].poNumber} deleted successfully.`
                                    : `Successfully deleted ${this.selectedPurchaseorders.length} Purchase Orders.`;
            this.$bvModal
                .msgBoxConfirm(
                    confirmMessage,
                    {
                        okTitle: 'Delete',
                        okVariant: 'danger'
                    })
                .then(value => {
                    if (value) {
                        this.isBusy = true;
                        let apiUrl = `/purchaseorders/delete`
                        let self = this
                        let selectedIds = this.selectedPurchaseorders.map(obj => obj.id);
                        axios
                            .post(apiUrl, {
                                purchaseOrderIds: selectedIds
                            })
                            .then(resp => {
                                if (resp) {
                                    this.selectedPurchaseorders.length = 0;
                                    self.$refs.searchScreen.refreshSearchResults();
                                    this.deletePOLabel = 'Delete PO';
                                    this.printPOLabel = 'Print PO';
                                    this.workPOLabel = 'Work PO';
                                    this.$toasted.global
                                        .app_success(successMessage)
                                        .goAway(5000);
                                }
                            })
                            .finally(() => {
                                this.isBusy = false;
                            });
                    }
                });
        },
        updateSearch(purchaseorders) {
            var self = this;
            axios.post('purchaseorders/search', purchaseorders).then(() => {
                self.refreshSearchResults = !self.refreshSearchResults;
            });
        },
        onSelection(purchaseorders) {
            this.selectedPurchaseorders = purchaseorders;
            if (purchaseorders.length <= 0) {
                this.deletePOLabel = 'Delete PO';
                this.printPOLabel = 'Print PO';
                this.workPOLabel = 'Work PO';
            } else if (purchaseorders.length == 1) {
                this.deletePOLabel = `Delete (${purchaseorders.length}) PO`;
                this.printPOLabel = `Print (${purchaseorders.length}) PO`;
                this.workPOLabel = `Work (${purchaseorders.length}) PO`;
            } else {
                this.deletePOLabel = `Delete (${purchaseorders.length}) POs`;
                this.printPOLabel = `Print (${purchaseorders.length}) POs`;
                this.workPOLabel = `Work (${purchaseorders.length}) POs`;
            }
        },
        selectedRowsChanged(selectedPurchaseOrders) {
            this.onSelection(selectedPurchaseOrders);
        },
        showExportPurchaseOrder() {
            this.$bvModal.show('exportPurchaseOrder');
        },
        handleModalOk: async function() {
            let selectedids = this.selectedPurchaseorders.map(obj => obj.id);
            let apiUrl = `/purchaseorders/printpurchaseorders`;

            this.isBusy = true;
            axios
                .post(
                    apiUrl,
                    {
                        ids: selectedids,
                        includeRetail: this.includeRetail,
                        exportFormat: 'pdf'
                    },
                    { responseType: 'blob' }
                )
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        `Print Purchase Orders`,
                        'application/pdf'
                    );
                    this.$toasted.global
                        .app_success(
                            `Print Purchase Orders report generated successfully.`
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        workPurchaseOrders() {
             var ids = this.selectedPurchaseorders.map(x => x.id.toString());
            this.$router.push({name: 'purchaseorders', params: {id : ids[0], ids: ids}});
        }
    }
};
</script>
<style scoped></style>
