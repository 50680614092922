<template>
    <SearchScreen
        title="Terms"
        api-destination="terms"
        edit-key="termId"
        :filters="filters"
        :filterBy="filterBy"
        :fields="fields"
        add-new-button
        :refreshSearchResults="refreshSearchResults"
        @isActiveChanged="updateIsActiveStatus"
    >
        
        <template v-slot:actionButtons>
            <p-button
                class="ml-2 mr-2"
                variant="btn btn-outline-primary"
                @click="showAuthorList()"
                >Author List</p-button
            >
            <AuthorListReport ref="authorList" />
        </template>
        <template v-slot:belowSearch>
            <p-form>
                <b-row class="pl-3 pr-2" cols="1" cols-sm="2" cols-xl="3">
                    <b-col>
                        <p-select
                            label="Show"
                            class="h-auto mr-2 shadow-none"
                            v-model="filters.returnOnlyOpenRecords"
                            :options="searchOptions"
                        />
                    </b-col>
                    <div class="w-100 d-xl-none"></div>
                    <b-col>
                        <p-datepicker
                            label="Start Date"
                            vid="termsStartDate"
                            v-model="filters.startDate"
                        />
                    </b-col>
                    <b-col>
                        <p-datepicker
                            label="End Date"
                            v-model="filters.endDate"
                            rules="dateGreaterOrEqual:@termsStartDate"
                            is-end-date
                        />
                    </b-col>

                    <b-col cols="2" xl="3" class="d-none d-lg-block"></b-col>
                </b-row>
            </p-form>
        </template>
    </SearchScreen>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import axios from 'axios';
import AuthorListReport from '@/views/Reports/Terms/AuthorList.vue';
import PButton from '../../components/p-button.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'TermsFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'termId',
                sortDesc: true,
                searchBy: 'All',
                startDate: null,
                endDate: null,
                returnOnlyOpenRecords: true
            },
            filterBy: ['All', 'Name', 'Description', 'Campus'],
            searchOptions: [
                { value: null, text: 'All Terms' },
                { value: true, text: 'Open Terms' },
                { value: false, text: 'Closed Terms' }
            ],
            refreshSearchResults: false,
            fields: [
                {
                    key: 'name',
                    sortable: true
                },
                {
                    key: 'description',
                    sortable: true
                },
                {
                    key: 'campus',
                    sortable: true
                },
                {
                    key: 'publishOnline',
                    label: 'Online Sharing',
                    sortable: true,
                    disabled: true
                },
                {
                    key: 'termId',
                    hidden: true
                }
            ]
        };
    },
    components: {
        SearchScreen,
        AuthorListReport,
        PButton
    },
    methods: {
        updateIsActiveStatus(term) {
            var self = this;
            axios.post('terms/search', term).then(() => {
                self.refreshSearchResults = !self.refreshSearchResults;
            });
        },
        showAuthorList() {
            this.$bvModal.show('exportAuthorListModal');
        }
    }
};
</script>
<style scoped></style>
