
export default {
    data() {
        return {}
    },
    async created() {
        var filters = localStorage.getItem(this.filterName);
        if (filters != null) {
            Object.assign(this.filters, JSON.parse(filters));
        }
    },
    async beforeRouteLeave(to, from, next) {
        if(this.filterExclude)
        {
            localStorage.setItem(
                this.filterName, 
                JSON.stringify(this.filters, (key, value) => {
                    return this.filterExclude.includes(key) ? undefined : value;
                }));
        } 
        else {            
            localStorage.setItem(this.filterName, JSON.stringify(this.filters));
        }
        next();
    }
}