<template>
    <div class="container-fluid content-wrapper">
        <div class="content-heading">{{ title }}</div>
        <b-row v-if="filters && locationId">
            <b-col cols="12" :sm="hasFilterSlot ? 9 : 12">
                <b-row>
                    <b-col class="mr-2 mb-3 ml-1  ">
                        <b-row class="pl-3">
                            <router-link
                                :to="addDestination()"
                                v-if="addNewButton"
                                class="btn btn-primary"
                            >
                                <b-icon-plus></b-icon-plus>
                                Add
                            </router-link>
                            <slot name="actionButtons">
                            </slot>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row v-if="!hideSearch" class="search-filters">
                    <b-col class="pl-0 pr-0">
                        <slot name="search">
                            <b-form>
                                <b-form-group
                                    label-for="automatic"
                                    class="ml-3 mt-2 mr-2"
                                >
                                    <b-form-row>
                                        <b-col>
                                            <b-input-group>
                                                <b-form-input
                                                    size="lg"
                                                    name="automatic"
                                                    ref='searchRef'
                                                    type="search"
                                                    v-model="filters.automatic"
                                                    :placeholder="
                                                        searchMessageComputed
                                                    "
                                                    debounce="300"
                                                    style="border-right: 0px;"
                                                    @keydown.enter.prevent=""
                                                />

                                                <b-input-group-append>
                                                    <slot name="appendSlot">
                                                    </slot>
                                                </b-input-group-append>
                                                <p-select
                                                    v-if="filterBy.length > 1"
                                                    set-invalid-value-to-first-option
                                                    class="ml-2 search-filter search-by-select"
                                                    style="height:15px;"
                                                    v-model="filters.searchBy"
                                                    :options="filterBy"
                                                />
                                            </b-input-group>
                                        </b-col>
                                        <slot name="afterSearch"> </slot>
                                    </b-form-row>
                                </b-form-group>
                            </b-form>
                            <slot name="belowSearch"> </slot>
                        </slot>
                    </b-col>
                </b-row>
                <b-row v-if="computedSelectionActions.length && showSetTo">
                    <b-dropdown
                        :disabled="selectedRows.length === 0"
                        text="Set To"
                        variant="outline-primary"
                        class="mb-3 ml-3"
                    >
                        <b-dropdown-item
                            v-for="selectionAction in computedSelectionActions"
                            :key="selectionAction.label"
                            @click="selectionAction.action"
                            :disabled="selectionAction.disabled"
                            v-show="!selectionAction.hidden"
                            >{{ selectionAction.label }}</b-dropdown-item
                        >
                    </b-dropdown>
                    <slot name="addButton"></slot>
                </b-row>

                <b-row>
                    <p-search-table
                        ref="searchTable"
                        v-model="selectedRows"
                        :include-search-input="false"
                        :sort-by="filters.sortBy"
                        :selectable="computedSelectionActions.length > 0"
                        :sort-desc="filters.sortDesc"
                        :api-destination="apiDestination"
                        :filters="computedFilters"
                        :use-bootstrap-selection="useBootstrapSelection"
                        :search-destination="searchDestination"
                        :per-page="filters.perPage"
                        :disable-initial-search="disableInitialSearch"
                        :per-page-options="perPageOptions"
                        @row-selected="rowSelected"
                        @input="rowSelected"
                        @per-page-change="perPageUpdate"
                        @sort-by-change="sortByUpdate"
                        @sort-desc-change="sortDescUpdate"
                        @total-rows-changed="
                            $emit('total-rows-changed', $event)
                        "
                        @selected-rows-changed="$emit('selected-rows-changed', $event)"
                        :fields="allFields"
                        @is-table-loading="$emit('is-table-loading', $event)"
                        :submit-search-as-post="submitSearchAsPost"
                        :edit-key="editKey"
                    >
                        <template
                            v-if="this.showEdit"
                            v-slot:cell(edit)="{ value, item }"
                        >
                            <router-link :to="editDestination(item)">
                                <b-button variant="outline-primary" size="sm"
                                    >Edit</b-button
                                >
                            </router-link>
                        </template>
                        <template
                            v-if="useIsActiveCheckbox"
                            v-slot:cell(isActive)="{ value, item, field }"
                        >
                            <b-form-checkbox
                                v-model="item.isActive"
                                :disabled="field.disabled"
                                name="isActive"
                                @change="isActiveChanged(item)"
                            >
                            </b-form-checkbox>
                        </template>
                        <template
                            v-for="(_, name) in $scopedSlots"
                            :slot="name"
                            slot-scope="slotData"
                        >
                            <slot :name="name" v-bind="slotData" />
                        </template>
                    </p-search-table>
                </b-row>
            </b-col>

            <b-col v-if="hasFilterSlot" cols="12" sm="3">
                <slot name="filters"></slot>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// See documentation at https://bootstrap-vue.js.org/docs/components/table

import axios from 'axios';
import { searchPlaceHolderFormatter } from './Common/Formatters';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    name: 'SearchScreen',
    props: {
        title: String,
        filters: Object,
        fields: Array,
        filterBy: Array,
        apiDestination: String,
        editKey: String,
        idKey: String,
        editUrlFunc: Function,
        addUrl: String,
        hideSearch: Boolean,
        addNewButton: Boolean,
        perPageOptions: {
            type: Array
        },
        perPage: {
            type: Number,
            default: 100
        },
        addBulkIsActiveToggle: {
            type: Boolean,
            default: false
        },
        useBootstrapSelection: {
            type: Boolean,
            default: true
        },
        disableInitialSearch: {
            type: Boolean,
            default: false
        },
        selectionActions: {
            type: Array,
            default: () => []
        },
        searchDestination: {
            type: String,
            default: null
        },
        sortBy: String,
        sortDesc: Boolean,
        showSetTo: {
            type: Boolean,
            default: true
        },
        useIsActiveCheckbox: {
            type: Boolean,
            default: true
        },
        submitSearchAsPost: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            selectedRows: [],
            defaultOption: 'All',
            locationId: null,
        };
    },
    computed: {
        computedSelectionActions() {
            //bulk IsActive toggle will be a common action, so we'll build it into search screen
            if (!this.addBulkIsActiveToggle) {
                return this.selectionActions;
            }
            return [
                {
                    label: 'Set to Active',
                    action: () => this.updateIsActiveStatus(true),
                    hidden: !this.selectedRows.some(x => !x.isActive)
                },
                {
                    label: 'Set to Inactive',
                    action: () => this.updateIsActiveStatus(false),
                    hidden: !this.selectedRows.some(x => x.isActive)
                },
                ...this.selectionActions
            ];
        },
        hasFilterSlot() {
            return !!this.$slots.filters;
        },
        showEdit() {
            return !!this.editKey || !!this.editUrlFunc;
        },
        allFields() {
            if (!this.showEdit) {
                return this.fields.filter(x => !x.hidden);
            }
            return [{ key: 'edit', class: 'tcw100' }].concat(
                this.fields.filter(x => !x.hidden)
            );
        },
        searchMessageComputed() {
            return searchPlaceHolderFormatter(this.filterBy,this.filters.searchBy);
        },
        computedFilters(){
            return {locationId: this.locationId, ...this.filters};
        }
    },
    methods: {
        getQueryParameters() {
            return this.$refs.searchTable.getQueryParameters();
        },
        updateIsActiveStatus(isActive) {
            let dto = {
                isActive: isActive
            };
            dto[`${this.idKey ?? this.editKey}s`] = this.selectedRows.map(
                x => x[this.idKey ?? this.editKey]
            );
            axios.post(`${this.apiDestination}/setisactive`, dto).then(() => {
                //Not auto refreshing the search results.  That loses the seleciton.
                //Also, if you were sorting by is active, the items you were looking at would move,
                //possibly off the page.

                //This could cause confusion
                //and not allow the user to easily undo a bulk action they just did.
                this.selectedRows.forEach(x => (x.isActive = isActive));
                var msg = `${isActive ? 'Activated' : 'De-activated'} ${
                    dto[`${this.idKey ?? this.editKey}s`].length
                } record(s) successfully`;
                this.$toasted.global.app_success(msg).goAway(5000);
            });
        },
        rowSelected(rows) {
            this.selectedRows = rows;
            this.$emit('selected', rows);
        },
        perPageUpdate(value) {
            this.filters.perPage = value;
        },
        sortByUpdate(value) {
            this.filters.sortBy = value;
        },
        sortDescUpdate(value) {
            this.filters.sortDesc = value;
        },
        editDestination(item) {
            if (this.editUrlFunc) {
                return this.editUrlFunc(item);
            }
            return `/${this.apiDestination}/${item[this.editKey]}`;
        },
        addDestination() {
            if (this.addUrl) {
                return this.addUrl;
            }
            return `/${this.apiDestination}/new`;
        },
        isActiveChanged(item) {
            this.$emit('isActiveChanged', item);
        },
        refreshSearchResults() {
            this.$refs.searchTable.refreshSearchResults();
        }
    },
    async beforeMount() {
        if(this.$refs.searchRef)
        {
            this.$refs.searchRef.select();
        }
        this.locationId =  await selectListOptionsDataContext.getSelectedLocationIdAsync();
    }
};
</script>

<style scoped lang="scss">
@import '@/styles/app/common/variables.scss';
.search-title {
    margin-bottom: 30px;
}

.search-filters {
    margin-bottom: 10px;
}
/deep/ .rounded-radio-group > label:first-child {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}
/deep/ .form-control:focus + .input-group-append {
    border: 1px solid $input-focus-border-color;
}
/deep/ .input-group-append {
    background-color: white;
    border: 1px solid $input-default-border-color;
    border-style: solid solid solid none;
    padding-right: 5px;
}
/deep/ .rounded-radio-group > label:last-child {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}
/deep/ .rounded-radio-group {
    margin-top: 8px;
}

>>> .table > tbody > tr > td {
    vertical-align: middle;
}
/deep/ .showEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 1.7;
}
/deep/ .tcw100 {
    width: 100px;
}

.search-filter /deep/ .custom-select {
    height: 43px;
}
</style>
