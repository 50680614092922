<template>
    <div>
        <SearchScreen title="Physical Inventory"
                      api-destination="physicalinventory"
                      edit-key="physicalInventoryId"
                      :filters="filters"
                      :filterBy="filterBy"
                      :fields="fields"
                      ref="inventorySearchScreen"
                      @total-rows-changed="e => (totalRows = e)"
                      add-new-button>
            <template v-slot:actionButtons>
                <ClearInventory class="ml-2 mr-3"></ClearInventory>
                <p-button class="mr-3"
                            variant="btn btn-outline-primary"
                            v-b-modal.postInventoryModal>Post Inventory</p-button>
                <b-overlay :show="isBusy"
                            rounded
                            opacity="0.5"
                            spinner-small
                            spinner-variant="primary"
                            class="d-inline-block">
                    <b-dropdown class="mr-1"
                                text="Export"
                                variant="outline-primary">
                        <b-dropdown-item v-b-modal.exportInventoryNotCountedModal
                                            :disabled="totalRows === 0">Inventory Not Counted</b-dropdown-item>
                        <b-dropdown-item v-b-modal.exportInventoryByDccModal
                                            :disabled="totalRows === 0">Inventory By DCC</b-dropdown-item>
                        <b-dropdown-item v-b-modal.exportInventoryByFixtureModal
                                            :disabled="totalRows === 0">Inventory By Fixture</b-dropdown-item>
                        <b-dropdown-divider />
                        <b-dropdown-item v-b-modal.exportInventoryValueModal>Total Inventory Value</b-dropdown-item>
                    </b-dropdown>
                </b-overlay>
            </template>
        </SearchScreen>
        <p-modal size="sm"
                 ref="postInventoryModal"
                 name="postInventoryModal"
                 label="Post Inventory"
                 hide-footer
                 @show="loadParameters">
            <b-row class='mb-4'>
                <b-col>
                    <p-checkbox v-model='postingParameters.postAsSales'
                                label='Post As Sales' />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button variant="btn btn-outline-primary"
                              @click="postInventory('partial')">Partial</b-button>
                </b-col>
                <b-col>
                    <b-button variant="btn btn-outline-primary"
                              @click="postInventory('complete')">Complete</b-button>
                </b-col>
            </b-row>
        </p-modal>

        <TotalInventoryReport></TotalInventoryReport>
        <InventoryByDcc ></InventoryByDcc>
        <InventoryByFixture ></InventoryByFixture>
        <InventoryNotCounted ></InventoryNotCounted>
    </div>
</template>

<script>
    import SearchScreen from '../../components/SearchScreen.vue';
    import ClearInventory from '../../components/ClearInventory.vue';
    import axios from 'axios';
    import TotalInventoryReport from '@/views/Reports/PhysicalInventory/TotalInventory.vue';
    import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
    import InventoryByDcc from '@/views/Reports/PhysicalInventory/InventoryByDcc.vue';
    import InventoryByFixture from '@/views/Reports/PhysicalInventory/InventoryByFixture.vue';
    import InventoryNotCounted from '@/views/Reports/PhysicalInventory/InventoryNotCounted.vue';
    import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

    export default {
        mixins: [SearchStorage],
        data() {
            return {
                filterName: 'PhysicalInvFilter',
                filters: {
                    automatic: "",
                    perPage: 100,
                    sortBy: 'fixture',
                    sortDesc: true,
                    searchBy: 'All'
                },
                filterBy: ['All', 'Fixture', 'Area', 'Batch'],
                fields: [
                    {
                        key: 'fixture',
                        sortable: true
                    },
                    {
                        key: 'area',
                        sortable: true
                    },
                    {
                        key: 'batch',
                        sortable: true
                    },
                    {
                        key: 'location',
                        sortable: true
                    }
                ],
                totalRows: 0,
                isBusy: false,
                postingParameters: {
                    postAsSales: false
                }
            };
        },
        components: {
            SearchScreen,
            ClearInventory,
            TotalInventoryReport,
            InventoryByDcc,
            InventoryByFixture,
            InventoryNotCounted
        },
        computed: {
        },
        methods: {
            loadParameters() {
                    this.postingParameters.postAsSales = false;
            },
            async postInventory(postType) {
                var locId = await selectListOptionsDataContext.getSelectedLocationIdAsync();

                if (postType == 'partial') {
                    this.$bvModal
                        .msgBoxConfirm(
                            'You are about to post PARTIAL and update the stock on hand for the scanned items.'
                        )
                        .then(value => {
                            if (value) {
                                axios
                                    .post('physicalinventory/postinventory', {
                                        postType: postType,
                                        postAsSales: this.postingParameters.postAsSales,
                                        locationId: locId
                                    })
                                    .then(resp => {
                                        if (resp) {
                                            this.$refs.postInventoryModal.hide();
                                            this.$toasted.global
                                                .app_success(
                                                    'Inventory has been posted and stock on hand has been updated.'
                                                )
                                                .goAway(5000);
                                            this.$router.go();
                                        }
                                    });
                            }
                        });
                } else {
                    this.$bvModal
                        .msgBoxConfirm(
                            'Use this option ONLY if you are doing a physical inventory of an entire store location. If you do NOT want non counted items to be set to 0 stock on hand, please hit cancel, and post partial.'
                        )
                        .then(value => {
                            if (value) {
                                axios
                                    .post('physicalinventory/postinventory', {
                                        postType: postType,
                                        postAsSales: this.postingParameters.postAsSales,
                                        locationId: locId
                                    })
                                    .then(resp => {
                                        if (resp) {
                                            this.$refs.postInventoryModal.hide();
                                            this.$toasted.global
                                                .app_success(
                                                    'Inventory has been posted and stock on hand has been updated.'
                                                )
                                                .goAway(5000);
                                            this.$router.go();
                                        }
                                    });
                            }
                        });
                }
            }
        }
    };
</script>
