import { render, staticRenderFns } from "./ClearInventory.vue?vue&type=template&id=04707644&scoped=true&"
import script from "./ClearInventory.vue?vue&type=script&lang=js&"
export * from "./ClearInventory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04707644",
  null
  
)

export default component.exports