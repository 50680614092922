<template>
    <SearchScreen
        title="Auto-Gen Records"
        api-destination="purchaseorderautogen"
        :filters="filters"
        :fields="fields"
        edit-key="id"
        :filter-by="['Description']"
        ref="searchScreen"
        add-new-button
        :refreshSearchResults="refreshSearchResults"
    />
</template>
<script>
import SearchScreen from '../../components/SearchScreen.vue';
import { dateFormatter } from '../../components/Common/Formatters.js';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
    mixins: [SearchStorage],
    data() {
        return {
            filterName: 'AutogenFilter',
            filters: {
                automatic: "",
                perPage: 100,
                sortBy: 'lastGenerateDate',
                sortDesc: true
            },
            refreshSearchResults: false,
            fields: [
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true
                },
                {
                    key: 'lastGenerateDate',
                    label: 'Last Generated Date',
                    sortable: true,
                    formatter: dateFormatter
                }
            ]
        };
    },
    components: {
        SearchScreen
    }
};
</script>
